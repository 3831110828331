import React, { useState, useEffect } from "react";

import { api } from "../../services/api";

import Container from "../../components/Container";
import Breadcrumb from "../../components/Breadcrumb";
import TopBar from "../../components/HorizontalLayout/TopBar";
import { ToastContainer, toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import loadingImg from "../../assets/animations/loading.gif";
import "./styles.scss";
import PdfReact from "../../components/PDF";

export function DocumentVisualization() {
  const [file, setFile] = useState("");
  const [service, setService] = useState();
  const [lotItem, setLotItem] = useState();

  const notifyError = () => toast.error("Error ao carregar o documento.");

  const id = window.location.pathname;
  const params = window.location.search;
  const search = params.replace("?search=", "");

  useEffect(() => {
    if (id) {
      api
        .get(`/viewer/buscaDados${id}`)
        .then(response => {
          setFile(response.data?.input);


          const serviceUrl = response?.data?.service;
          const lotItems = response?.data?.lotItem;
          setService(serviceUrl);
          setLotItem(lotItems);
        })
        .catch(error => {
          notifyError();
        });
    }
  }, [id]);

  const lotId = lotItem?.lotId;
  const dfUrlView = service?.viewUrl?.replace("{lot_id}", lotId);

  return (
    <Container>
      <ToastContainer />
      <TopBar />
      <Breadcrumb
        title="Visualizacao de Documento"
        items={[
          {
            title: "Documento",
            path: "/Unicontent",
          },
        ]}
        actions={[
          {
            title: "Voltar",
            action: () => {},
          },
        ]}
      />

      <div className="document_container">
        {file ? (
          <>
            <div className="list md-6">
              <h1 style={{ marginBottom: 20 }}>Informações do Documento</h1>

              <object type="text/html" data={dfUrlView} height="100%">
                {}
              </object>
            </div>
            <div className="pdf">
              <PdfReact pdf={file} search={search || ""} />
            </div>
          </>
        ) : (
          <div className="img-container">
            <img src={loadingImg} alt="Loading..." />
          </div>
        )}
      </div>
    </Container>
  );
}
