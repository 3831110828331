import React, { Component } from "react";
import { Link } from "react-router-dom";

// import images
//import logo from "../../assets/images/logo.png";

// Import other Dropdown
//import NotificationDropdown from "../../components/NotificationDropdown";

class TopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.toggleFullscreen = this.toggleFullscreen.bind(this);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleRightbar = this.toggleRightbar.bind(this);
  }

  /**
   * Toggle sidebar
   */
  toggleMenu() {
    // this.props.toggleMenuCallback();
    this.props.openLeftMenuCallBack();
  }

  /**
   * Toggles the sidebar
   */
  toggleRightbar() {
    this.props.toggleRightSidebar();
  }

  /**
   * Toggle full screen
   */
  toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  render() {
    return (
      <header id="page-topbar">
        <div className="d-flex align-items-center justify-content-between pl-4 ">
          <Link to="/" className="logo logo-dark">
            <span className="logo-lg">
              <h1 style={{ color: "#3c74e4" }}>Visualizador</h1>
            </span>
          </Link>

          <div className="dropdown d-none d-lg-inline-block">
            <button
              type="button"
              className="btn header-item noti-icon waves-effect pr-4"
              onClick={this.toggleFullscreen}
            >
              <i className="mdi mdi-fullscreen"></i>
            </button>
          </div>
        </div>
      </header>
    );
  }
}

export default TopBar;
