import React, { useState, useEffect } from "react";
import { Worker } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

import SearchSidebarDefaultLayout from "./SearchSidebarLayout";

const ReactPdf = ({ pdf, search }) => {
  const [show, setShow] = useState(true);
  const [fileKeywords, setFileKeywords] = useState({
    fileUrl: "data:application/pdf;base64," + pdf,
    keywords: [],
  });

  useEffect(() => {
    if (search !== "" && show) {
      setFileKeywords({
        fileUrl: "data:application/pdf;base64," + pdf,
        keywords: [search],
      });
      setShow(false);
    }
  }, [fileKeywords, pdf, search, show]);

  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.7.570/build/pdf.worker.js">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100vh",
          width: "100%",
          margin: "0 auto",
        }}
      >
        <div style={{ flex: 1, overflow: "hidden" }}>
          <SearchSidebarDefaultLayout
            fileUrl={fileKeywords.fileUrl}
            keywords={fileKeywords.keywords}
          />
        </div>
      </div>
    </Worker>
  );
};

export default ReactPdf;
