import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

function Breadcrumb({ title, items, actions }) {
  return (
    <Row className="align-items-center">
      <Col sm={6}>
        <div className="page-title-box">
          <h4 className="font-size-18">{title}</h4>
          <ol className="breadcrumb mb-0">
            <>
              {
                items.map((item, index) => {
                  if (index === items.length - 1) {
                    return <li key={item.title} className="breadcrumb-item active">{item.title}</li>
                  }
                  return (
                    <li key={item.title} className="breadcrumb-item">
                      <Link to={item.path}>{item.title}</Link>
                    </li>
                  )
                })
              }
            </>
          </ol>
        </div>
      </Col>
      {
        actions && (
          <Col sm="6">
            <div className="float-right d-none d-md-block d-flex">
              {
                actions.map(button => {


                  if (button?.loading) {
                    return (
                      <div className="spinner-border text-primary mt-4" role="status">
                        <span className="sr-only">{button?.textLoading || 'Cadastrado'}...</span>
                      </div>
                    )
                  }
                  return (
                    <button key={button.title} disabled={button?.disabled || false} type={button.type || 'button'} color={button?.color || 'primary'} onClick={button.action} className={`btn ${button?.color || 'btn-primary'} waves-effect waves-light mr-4 mb-3`}>
                      {button.title}
                    </button>
                  )
                })
              }
            </div>
          </Col>
        )
      }
    </Row>
  );
}

export default Breadcrumb;
