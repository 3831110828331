import React from "react";
import { Route, Switch } from "react-router-dom";

import { DocumentVisualization } from "../pages/DocumentVisualization";

export const Routes = () => {
  return (
    <Switch>
      <Route path="/:id" component={DocumentVisualization} exact />
    </Switch>
  );
};
