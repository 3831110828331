import React from 'react';
import { BrowserRouter as Router} from 'react-router-dom';
import { Toaster } from 'react-hot-toast';

import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { Routes } from './routes';

// Impor scss
import './assets/scss/theme.scss';
import './App.css';

import 'react-datepicker/dist/react-datepicker.css';

const App = () => {

  return (
    <Router>
        <Routes />
        <Toaster position="top-right"/>
    </Router>
  );
};

export default App;
